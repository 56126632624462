<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Configuration</h4>
        <Toolbar class="mb-4" v-if="Permissions.add">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter une nouvelle configuration"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="showAddConf()"
              />
            </div>
          </template>
        </Toolbar>
        <DataTable
          :value="configurations"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'name',
            'nbr_reponse',
            'nbr_reparation',
            'prime',
            'remarque',
          ]"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucune Configuration trouvée. </template>
          <template #loading>
            Chargement des données Configuration. Veuillez patienter.
          </template>
          <Column
            field="name"
            header="Type Contrat"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <Column
            field="nbr_reponse"
            header="Nbr heure de réponse"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.nbr_reponse }}
            </template>
          </Column>
          <Column
            field="nbr_reparation"
            header="nbr heure de réparation"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.nbr_reparation }}
            </template>
          </Column>
          <Column
            field="prime"
            header="Prime"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.prime }}
            </template>
          </Column>
          <Column
            field="remarque"
            header="Remarque"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.remarque }}
            </template>
          </Column>
          <Column header="Actions" style="min-width: 12rem">
            <template #body="data">
              <span class="p-buttonset">
                <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                <Button
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="updateClient(data.data)"
                />
              </span>
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="dialogOfAdd"
          :style="{ width: '900px' }"
          header="Ajouter une configuration"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <label for="lastname2">Type Contrat</label>
                <Dropdown
                  :class="{ 'is-invalid': errorsAdd['contracttype_id'] }"
                  v-model="contracttype_id"
                  :options="contracttypes"
                  optionLabel="name"
                  placeholder="sélectionner Type Contrat"
                  :filter="true"
                ></Dropdown>
                <small class="p-error" v-if="errorsAdd['contracttype_id']">
                  {{ errorsAdd["contracttype_id"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="firstname2">Nbr heure de réponse </label>

                <InputNumber
                  v-model="configToAdd.nbr_reponse"
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-success"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  :min="0"
                />
                <small class="p-error" v-if="errorsAdd['nbr_reponse']">
                  {{ errorsAdd["nbr_reponse"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="firstname2">Nbr heure de réparation </label>

                <InputNumber
                  v-model="configToAdd.nbr_reparation"
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-success"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  :min="0"
                />
                <small class="p-error" v-if="errorsAdd['nbr_reparation']">
                  {{ errorsAdd["nbr_reparation"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="firstname2">Prime en MAD </label>

                <InputNumber
                  v-model="configToAdd.prime"
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-success"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  :min="0"
                />
                <small class="p-error" v-if="errorsAdd['prime']">
                  {{ errorsAdd["prime"] }}
                </small>
              </div>
              <div class="field col-12 md:col-12">
                <label for="city">Remarque </label>
                <InputText
                  v-model="configToAdd.remarque"
                  type="text"
                  :class="{ 'is-invalid': errorsUpdate['remarque'] }"
                />
                <small class="p-error" v-if="errorsUpdate['remarque']">
                  {{ errorsUpdate["remarque"] }}
                </small>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfAdd = false"
            />
            <Button
              label="ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfEdit"
          :style="{ width: '900px' }"
          header="Modifier"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <h4 style="color: green">{{ nameOfC }}</h4>
              </div>
              <div class="field col-12 md:col-4">
                <label for="firstname2">Nbr heure de réponse </label>

                <InputNumber
                  v-model="configToUpdate.nbr_reponse"
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-success"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  :min="0"
                />
                <small class="p-error" v-if="errorsUpdate['nbr_reponse']">
                  {{ errorsUpdate["nbr_reponse"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="firstname2">Nbr heure de réparation </label>

                <InputNumber
                  v-model="configToUpdate.nbr_reparation"
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-success"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  :min="0"
                />
                <small class="p-error" v-if="errorsUpdate['nbr_reparation']">
                  {{ errorsUpdate["nbr_reparation"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="firstname2">Prime en MAD </label>

                <InputNumber
                  v-model="configToUpdate.prime"
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-success"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  :min="0"
                />
                <small class="p-error" v-if="errorsUpdate['prime']">
                  {{ errorsUpdate["prime"] }}
                </small>
              </div>
              <div class="field col-12 md:col-12">
                <label for="city">Remarque </label>
                <InputText
                  v-model="configToUpdate.remarque"
                  type="text"
                  :class="{ 'is-invalid': errorsUpdate['remarque'] }"
                />
                <small class="p-error" v-if="errorsUpdate['remarque']">
                  {{ errorsUpdate["remarque"] }}
                </small>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfEdit = false"
            />
            <Button
              label="Modifier"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormUpdate"
            />
          </template>
        </Dialog>
        <ConfirmDialog></ConfirmDialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      Permissions: { update: false, delete: false, add: false, show: false },
      configurations: [],
      filters1: null,
      loading1: true,
      dialogOfEdit: false,
      configToUpdate: {
        id: 0,
        contracttype_id: null,
        nbr_reponse: 0,
        nbr_reparation: 0,
        prime: 0,
        remarque: null,
      },
      nameOfC: null,
      errorsUpdate: [],
      dialogOfAdd: false,

      configToAdd: {
        contracttype_id: null,
        nbr_reponse: 0,
        nbr_reparation: 0,
        prime: 0,
        remarque: null,
      },
      contracttypes: [],
      errorsAdd: [],
      contracttype_id: null,
    };
  },

  mounted() {
    this.Permissions.update =
      localStorage.permissionNames.includes("Gestion database");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Gestion database");
    this.Permissions.add =
      localStorage.permissionNames.includes("Gestion database");
    this.Permissions.show =
      localStorage.permissionNames.includes("Gestion database");
    if (this.Permissions.show) {
      this.showAllConf();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    // if (localStorage.token == "" || localStorage.token == null) {
    //   this.$router.push("/login");
    // }
    this.initFilters1();
  },
  methods: {
    updateclient() {
      axios
        .post(
          "updateconfiguration/" + this.configToUpdate.id,
          this.configToUpdate
        )
        .then((response) => {
          console.log("dazet", response);
          this.showAllConf();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfEdit = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    initformadd() {
      this.configToAdd.contracttype_id = null;
      this.configToAdd.nbr_reponse = 0;
      this.configToAdd.nbr_reparation = 0;
      this.configToAdd.prime = 0;
      this.configToAdd.remarque = null;
      this.contracttype_id = null;
      this.errorsAdd = [];
    },
    showAllConf() {
      axios
        .get("getconfigurations")
        .then((response) => {
          console.log(response);
          this.configurations = response.data.configurations;
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    addConf() {
      axios
        .post("addconfiguration", this.configToAdd)
        .then((response) => {
          this.showAllConf();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfAdd = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    showAddConf() {
      this.initformadd();
      this.errorsAdd = [];
      axios
        .get("formAddconfiguration")
        .then((response) => {
          this.contracttypes = response.data.available;
          this.dialogOfAdd = true;
          console.log(response.data);
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    checkFormAdd() {
      let checked = true;
      this.errorsAdd = [];

      if (this.contracttype_id) {
        this.configToAdd.contracttype_id = this.contracttype_id.id;
      } else {
        this.errorsAdd["contracttype_id"] = "Type Contrat est obligatoire.";
        checked = false;
      }
      if (this.configToAdd.nbr_reponse == null) {
        this.errorsAdd["nbr_reponse"] = "Nbr heure de réponse est obligatoire.";
        checked = false;
      }
      if (this.configToAdd.nbr_reparation == null) {
        this.errorsAdd["nbr_reparation"] =
          "Nbr heure de réparation est obligatoire.";
        checked = false;
      }
      if (this.configToAdd.prime == null) {
        this.errorsAdd["prime"] = "Prime est obligatoire.";
        checked = false;
      }

      if (checked) {
        console.log(this.errorsAdd);
        this.addConf();
      }
    },
    checkFormUpdate() {
      let checked = true;
      this.errorsUpdate = [];
      if (this.configToUpdate.nbr_reponse == null) {
        this.errorsUpdate["nbr_reponse"] =
          "Nbr heure de réponse est obligatoire.";
        checked = false;
      }
      if (this.configToUpdate.nbr_reparation == null) {
        this.errorsUpdate["nbr_reparation"] =
          "Nbr heure de réparation est obligatoire.";
        checked = false;
      }
      if (this.configToUpdate.prime == null) {
        this.errorsUpdate["prime"] = "Prime est obligatoire.";
        checked = false;
      }

      if (checked) {
        this.updateclient();
      }
    },
    updateClient(configuration) {
      this.errorsUpdate = [];
      this.configToUpdate.id = configuration.id;
      this.configToUpdate.contracttype_id = configuration.contracttype_id;
      this.configToUpdate.nbr_reponse = configuration.nbr_reponse;
      this.configToUpdate.nbr_reparation = configuration.nbr_reparation;
      this.configToUpdate.prime = configuration.prime;
      this.configToUpdate.remarque = configuration.remarque;
      this.nameOfC = configuration.name;
      this.dialogOfEdit = true;
    },

    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formatDate(value) {
      return value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    calculateCustomerTotal(name) {
      let total = 0;
      if (this.customer3) {
        for (let customer of this.customer3) {
          if (customer.representative.name === name) {
            total++;
          }
        }
      }

      return total;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
@import "../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>